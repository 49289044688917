<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <el-button type="primary" @click="handleEdit()" v-permission="['新增活动分类']">新增</el-button>
      </div>
      <div class="search">
        <el-input v-model="name" type="text" placeholder="请输入分类名称" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <el-table :data="tableData.items" v-loading="loading">
      <el-table-column prop="name" label="分类名称"></el-table-column>
      <el-table-column prop="index" label="排序"></el-table-column>
      <el-table-column prop="activity_count" label="活动数"></el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="220">
        <template #default="scope">
          <div class="flex-align">
            <el-button plain size="small" type="primary" @click="handleEdit(scope.row)" v-permission="['编辑活动分类']"
              >编辑</el-button
            >
            <el-button
              plain
              size="small"
              type="danger"
              @click="handleDelete(scope.row.id)"
              v-permission="['删除活动分类']"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <edit-cate ref="editCate" @confirm="getData"></edit-cate>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'
import { useRouter, useRoute } from 'vue-router'
import commonHandle from '@/hooks/useCommon'
import activityModel from '@/model/activity'
import editCate from './components/edit-cate.vue'

export default {
  components: {
    editCate,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const name = ref()
    const ctx = getCurrentInstance()

    const getData = async pageQuery => {
      loading.value = true
      try {
        const res = await activityModel.getActCateList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
          name: name.value,
        })
        tableData.value = res
        return res
      } finally {
        loading.value = false
      }
    }

    const handleEdit = (row = {}) => {
      ctx.refs.editCate.init(row)
    }

    const deleteFun = async id => {
      let res
      res = await activityModel.delActCate(id)
      return res
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      getData()
    })

    return {
      name,

      tableData,
      loading,
      pageParam,
      handleDelete,
      pageEmit,
      getData,
      handleEdit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';

.header .search .el-input {
  width: 250px !important;
}
</style>
